import React, { Component } from 'react';
import fb from './facebook.png';
import youtube from './youtube.png';
import ig from './instagram.png';
import soundcloud from './soundcloud.png';
import twitter from './twitter.png';
import './App.css';
import Form from './Form';
import FormErrors from './FormErrors';


class App extends Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <h1 className="title forum">The Rosannah Sisters</h1>
          </div>
        </div>
        <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 offset-md-3 bg-grey header">
              <h4 className="forum">Music</h4>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 offset-md-3">
                <iframe className="bandcamp top" title="diagrams" src="https://bandcamp.com/EmbeddedPlayer/album=1142212865/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/" seamless><a href="http://therosannahsisters.bandcamp.com/album/diagrams">Diagrams by The Rosannah Sisters</a></iframe>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 offset-md-3">
                <iframe className="bandcamp top" title="whatever-happens" src="https://bandcamp.com/EmbeddedPlayer/album=3994565001/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/" seamless><a href="http://therosannahsisters.bandcamp.com/album/whatever-happens">Whatever Happens by The Rosannah Sisters</a></iframe>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 offset-md-3">
                <iframe className="bandcamp" title="wishing-well" src="https://bandcamp.com/EmbeddedPlayer/album=986202543/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/" seamless><a href="http://therosannahsisters.bandcamp.com/album/wishing-well-ep">Wishing Well [EP] by The Rosannah Sisters</a></iframe>
            </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 offset-md-3 bg-grey header">
            <h4 className="forum">Lake Michigan</h4>    
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 offset-md-3">
            <div className="video-wrapper">
              <iframe title="lake-michigan" className="youtube" width="540" height="315" src="https://www.youtube.com/embed/UO3zuSJgtU0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 offset-md-3 bg-grey header">
              <h4 className="forum">Contact Us</h4>
              <div className="icon">
                  <a href="https://soundcloud.com/rosannahmusic"><img height="27px" width="27px" src={soundcloud} alt=''></img></a>
                  <a href="https://www.youtube.com/user/RosannahMusic/featured"><img height="27px" width="27px" src={youtube} alt=''></img></a>
                  <a href="https://www.facebook.com/rosannahmusic/"><img height="27px" width="27px" src={fb} alt=''></img></a>
                  <a href="https://twitter.com/rosannahmusic"><img height="27px" width="27px" src={twitter} alt=''></img></a>
                  <a href="https://www.instagram.com/rosannahmusic/"><img height="27px" width="27px" src={ig} alt=''></img></a>
              </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 offset-md-3">
          </div>
        </div>
        <footer class="text-center">
          <p>&copy; - The Rosannah Sisters</p>
        </footer>
      </div>
    );
  }
}

export default App;
